import axios from "axios/index";
import qs from 'qs';

export default {
  state: {
    boardNotice: [],
    boardEvent: [],
    boardFAQ: [],
  },
  getters: {
    boardNotice: state => state.boardNotice,
    boardEvent: state => state.boardEvent,
    boardFAQ: state => state.boardFAQ,
  },
  actions: {
    GET_NOTICE_DATA({ state }) {
      // console.log('GET_NOTICE_DATA (action) =========================== ');
      const params = {
        lang: state.locale,
      };
      this.commit('SET_LOADING', true);
      axios
        .post('/board/notice/v1.json', qs.stringify(params))
        .then((res) => {
          const payload = res.data.data;

          if (payload.length > 0) {
            this.commit('SET_NOTICE_DATA', payload);
          } else {
            alert('SNS Login이 필요 합니다.');
          }
        })
        .catch((err) => {
          throw Error(`서버 데이터 오류 (getGlobalInfoAPI) => ${err}`);
        })
        .finally(() => {
          this.commit('SET_LOADING', false);
        });
    },
    GET_EVENT_DATA({ state }) {
      // console.log('GET_EVENT_DATA (action) =========================== ');
      const params = {
        lang: state.locale,
      };
      this.commit('SET_LOADING', true);
      axios
        .post('/board/event/v1.json', qs.stringify(params))
        .then((res) => {
          const payload = res.data.data;
          const isClubLogin = payload['login'];

          if (payload.length > 0) {
            this.commit('SET_EVENT_DATA', payload);
          } else {
            alert('SNS Login이 필요 합니다.');
          }
        })
        .catch((err) => {
          throw Error(`서버 데이터 오류 (getGlobalInfoAPI) => ${err}`);
        })
        .finally(() => {
          this.commit('SET_LOADING', false);
        });
    },
    GET_FAQ_DATA({ state }) {
      // console.log('GET_FAQ_DATA (action) =========================== ');
      const params = {
        lang: state.locale,
      };
      this.commit('SET_LOADING', true);
      axios
        .post('/board/faq/v1.json', qs.stringify(params))
        .then((res) => {
          const payload = res.data.data;
          const isClubLogin = payload['login'];

          if (payload.length > 0) {
            this.commit('SET_FAQ_DATA', payload);
          } else {
            alert('SNS Login이 필요 합니다.');
          }
        })
        .catch((err) => {
          throw Error(`서버 데이터 오류 (getGlobalInfoAPI) => ${err}`);
        })
        .finally(() => {
          this.commit('SET_LOADING', false);
        });
    },
  },
  mutations: {
    SET_NOTICE_DATA(state, payload) {
      // console.log('SET_NOTICE_DATA(mutation) =========================== ', payload);
      state.boardNotice = payload;
    },
    SET_EVENT_DATA(state, payload) {
      // console.log('SET_EVENT_DATA(mutation) =========================== ', payload);
      state.boardEvent = payload;
    },
    SET_FAQ_DATA(state, payload) {
      // console.log('SET_FAQ_DATA(mutation) =========================== ', payload);
      state.boardFAQ = payload;
    },
  },
};