<template>
  <md-toolbar
    id="toolbar"
    md-elevation="0"
    class="ac-toolbar md-fixed"
    :class="{
      [`md-${type}`]: !navbarTransparent,
      [extraNavClasses]: navbarTransparent,
      'md-transparent': navbarTransparent
    }"
    :color-on-scroll="colorOnScroll"
  >
    <div class="md-toolbar-row md-collapse-lateral">
      <div class="md-toolbar-section-start">
        <h3 class="md-title">
          <router-link to="/"
                       class="md-button md-button-link md-white md-simple dropdown-toggle">
            <img class="ac-logo" :src="logo_ac" alt="discord">
          </router-link>
        </h3>
      </div>
      <div class="md-toolbar-section-end">
        <div class="md-toolbar-toggle"
             :class="{ toggled: toggledClass }">

          <a v-for="(sns, index) in resource.sns"
             :key="`sns_p_${index}`"
             :href="sns.url"
             target="_blank">
            <img :src="sns.icon_p" :alt="sns.name" class="desktop">
          </a>
          <md-button class="md-just-icon md-simple"
                     @click="toggleNavbarMobile()">
            <span class="icon-bar" />
            <span class="icon-bar" />
            <span class="icon-bar" />
          </md-button>

        </div>

        <div class="md-collapse">
          <div class="md-collapse-wrapper">
            <mobile-menu nav-mobile-section="false">
              <!-- Here you can add your items from the section-start of your toolbar -->
            </mobile-menu>

            <md-list>
              <template v-for="(route, key) in menus">
                <li v-if="route.length > 1" class="md-list-item" :key="key">
                  <a href="javascript:void(0)"
                     class="md-list-item-router md-list-item-container md-button-clean dropdown">
                    <div class="md-list-item-content">
                      <drop-down direction="down">
                        <md-button slot="title"
                                   class="md-button md-button-link md-white md-simple dropdown-toggle"
                                   data-toggle="dropdown">
                          <i v-if="key === 'sns'" class="material-icons">apps</i>
                          <p>{{key}}</p>
                        </md-button>
                        <ul class="dropdown-menu dropdown-with-icons">
                          <li v-for="(child, i) in route" :key="`accordion_${i}`">
                            <router-link :to="child.path"
                                         @click.native="toggleNavbarMobile()">
                              {{child.name}}
                            </router-link>
                          </li>
                        </ul>
                      </drop-down>
                    </div>
                  </a>
                </li>
                <li v-else class="md-list-item" :key="key">
                  <a class="md-list-item-router md-list-item-container md-button-clean">
                    <div class="md-list-item-content">
                      <router-link :to="`${route[0].path}`"
                                   @click.native="toggleNavbarMobile()"
                                   class="md-button md-button-link md-white md-simple dropdown-toggle">
                        <p>{{key}}</p>
                      </router-link>
                    </div>
                  </a>
                </li>
              </template>
              <li class="md-list-item">
                <a href="javascript:void(0)"
                   class="md-list-item-router md-list-item-container md-button-clean dropdown">
                  <div class="md-list-item-content">
                    <drop-down direction="down">
                      <md-button slot="title"
                                 class="md-button md-button-link md-white md-simple dropdown-toggle"
                                 data-toggle="dropdown">
                        <i class="material-icons">view_carousel</i>
                        <p>SNS</p>
                      </md-button>
                      <ul class="dropdown-menu dropdown-with-icons">
                        <li v-for="(sns, index) in resource.sns" :key="`sns_m_${index}`">
                          <a :href="sns.url" target="_blank">
                            <img :src="sns.icon_m" :alt="sns.name" class="mobile">
                            {{sns.name}}
                          </a>
                        </li>
                      </ul>
                    </drop-down>
                  </div>
                </a>
              </li>
              <li class="md-list-item">
                <a href="javascript:void(0)"
                   class="md-list-item-router md-list-item-container md-button-clean dropdown">
                  <div class="md-list-item-content">
                    <drop-down class="ac-locale-wrap"
                               direction="down">
                      <md-button slot="title"
                                 class="md-button md-button-link md-white md-simple dropdown-toggle"
                                 data-toggle="dropdown">
                        <!--<i class="material-icons">view_carousel</i>-->
                        <p>LANGUAGE</p>
                      </md-button>
                      <ul class="dropdown-menu dropdown-menu-right dropdown-with-icons">
                        <li>
                          <a href="javascript:void(0)" @click="changeLocale('ko')">
                            Korean
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)" @click="changeLocale('en')">
                            English
                          </a>
                        </li>
                      </ul>
                    </drop-down>
                  </div>
                </a>
              </li>
            </md-list>
          </div>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import Mixins from "@/plugins/basicMixins";
  import MobileMenu from "@/layout/MobileMenu";

  export default {
    components: {
      MobileMenu
    },
    props: {
      type: {
        type: String,
        default: "white",
        validator(value) {
          return [
            "white",
            "default",
            "primary",
            "danger",
            "success",
            "warning",
            "info",
            "dark",
            "anipang",
          ].includes(value);
        }
      },
      colorOnScroll: {
        type: Number,
        default: 0
      },
      navbarTransparent: {
        type: Boolean,
        default: false
      }
    },
    mixins: [Mixins.HeaderImage],
    computed: {
      ...mapGetters([
        "language",
        "base",
        "locale",
      ]),
    },
    mounted() {
      this.selectLocale = this.locale;
      const routes =  this.$router.options.routes;
      const category = {};

      routes.forEach((item) => {
        if (item.category && !{}.hasOwnProperty.call(category, item.category)) {
          category[item.category] = [];
        }
        if (item.category) {
          category[item.category].push(item)
        }

      });
      this.menus = category;
      document.addEventListener("scroll", this.scrollListener);
    },
    watch: {
      locale(value) {
        this.selectLocale = value;
      }
    },
    beforeDestroy() {
      document.removeEventListener("scroll", this.scrollListener);
    },
    methods: {
      ...mapActions([
        "GET_LANGUAGE_DATA",
      ]),
      changeLocale(locale) {
        this.toggleNavbarMobile();
        this.$store.dispatch('GET_LANGUAGE_DATA', locale);
      },
      // ////////////////////////////////////////
      bodyClick() {
        let bodyClick = document.getElementById("bodyClick");

        if (bodyClick === null) {
          let body = document.querySelector("body");
          let elem = document.createElement("div");
          elem.setAttribute("id", "bodyClick");
          body.appendChild(elem);

          let bodyClick = document.getElementById("bodyClick");
          bodyClick.addEventListener("click", this.toggleNavbarMobile);
        } else {
          bodyClick.remove();
        }
      },
      toggleNavbarMobile() {
        this.NavbarStore.showNavbar = !this.NavbarStore.showNavbar;
        this.toggledClass = !this.toggledClass;
        this.bodyClick();
      },
      handleScroll() {
        let scrollValue = document.body.scrollTop || document.documentElement.scrollTop;
        let navbarColor = document.getElementById("toolbar");

        this.currentScrollValue = scrollValue;
        if (this.colorOnScroll > 0 && scrollValue > this.colorOnScroll) {
          this.extraNavClasses = `md-${this.type}`;
          navbarColor.classList.remove("md-transparent");
        } else {
          if (this.extraNavClasses) {
            this.extraNavClasses = "";
            navbarColor.classList.add("md-transparent");
          }
        }
      },
      scrollListener() {
        window.resizeThrottler(this.handleScroll);
      }
    },
    data() {
      return {
        menus: {},
        extraNavClasses: "",
        toggledClass: false,
        selectLocale: '', // navigator.language.split('-')[0],

        logo_ac: require("@/assets/img/logos/bi_anipangclub_w.png"),
        sns_dc: require("@/assets/img/icons/sns_dc.png"),
        sns_dc_m: require("@/assets/img/icons/sns_dc_m.png"),
        sns_tg: require("@/assets/img/icons/sns_tg.png"),
        sns_tg_m: require("@/assets/img/icons/sns_tg_m.png"),
        sns_tw: require("@/assets/img/icons/sns_tw.png"),
        sns_tw_m: require("@/assets/img/icons/sns_tw_m.png"),

        resource: {
          sns: [
            {
              name: 'Discord',
              url: 'https://discord.gg/anipangclub',
              icon_p: require("@/assets/img/icons/sns_dc.png"),
              icon_m: require("@/assets/img/icons/sns_dc_m.png"),
            },
            {
              name: 'Telegram',
              url: 'https://t.me/+KmVRmEVPmIEwMmE1',
              icon_p: require("@/assets/img/icons/sns_tg.png"),
              icon_m: require("@/assets/img/icons/sns_tg_m.png"),
            },
            {
              name: 'Twitter',
              url: 'https://twitter.com/anipang_club',
              icon_p: require("@/assets/img/icons/sns_tw.png"),
              icon_m: require("@/assets/img/icons/sns_tw_m.png"),
            },
          ],
        },
      };
    },
  };
</script>
<style lang="scss"></style>
