<template>
  <div id="vue-material-kit-pro"
       :class="{ 'nav-open': NavbarStore.showNavbar }">
    <ToastPopup/>
    <router-view name="header" />
    <router-view />
    <router-view name="footer" />
  </div>
</template>

<script>
  import { ToastPopup } from "@/components";

  export default ({
    components: {
      ToastPopup,
    },
    computed: {},
    mounted() {
      this.$store.dispatch('INITIALIZE_APP');
    },
    methods: {},
    data() {
      return {};
    },
  })
</script>

<style lang="scss">
</style>