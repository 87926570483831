import axios from "axios/index";
import qs from 'qs';

export default {
  state: {
    publicNFT: {},
    publicNFTDetail: {},
  },
  getters: {
    publicNFT: state => state.publicNFT,
    publicNFTDetail: state => state.publicNFTDetail,
  },
  actions: {
    GET_PUBLIC_NFT_LIST_DATA({ state }, payload) {
      // console.log('GET_PUBLIC_NFT_LIST_DATA (action) =========================== ', payload);
      let params = {};

      if (state.publicNFT['nftFilter']) {
        const apply = {};

        Object.keys(state.publicNFT.nftFilter).forEach((type) => {
          apply[type] = [];
          Object.keys(state.publicNFT.nftFilter[type]).forEach((item) => {
            if (state.publicNFT.nftFilter[type][item].isApply) {
              apply[type].push(item)
            }
          });
        });
        params = {
          page : state.publicNFT.page.no,
          filter : JSON.stringify(apply)
        };
      }

      this.commit('SET_LOADING', true);
      axios
        .post('/apnft/market/list/v2.json', qs.stringify(params))
        .then((res) => {
          const payload = res.data.data;

          if (payload) {
            this.commit('SET_PUBLIC_NFT_LIST_DATA', payload);
          } else {
            alert('GET_PUBLIC_NFT_LIST_DATA 잘못됨');
          }
        })
        .catch((err) => {
          throw Error(`서버 데이터 오류 (GET_PUBLIC_NFT_LIST_DATA) => ${err}`);
        })
        .finally(() => {
          this.commit('SET_LOADING', false);
        });
    },

    GET_PUBLIC_NFT_DETAIL_DATA({ state }, payload) {
      // console.log('GET_PUBLIC_NFT_DETAIL_DATA (action) =========================== ', payload);
      let params = {tokenId: payload.id};

      this.commit('SET_LOADING', true);
      axios
        .post('/apnft/market/detail/v1.json', qs.stringify(params))
        .then((res) => {
          const payload = res.data.data;

          if (payload) {
            this.commit('SET_PUBLIC_NFT_DETAIL_DATA', payload);
          } else {
            alert('SET_PUBLIC_NFT_DETAIL_DATA 잘못됨-----');
            /* doto test code */
          }
        })
        .catch((err) => {
          throw Error(`서버 데이터 오류 (GET_PUBLIC_NFT_DETAIL_DATA) => ${err}`);
        })
        .finally(() => {
          this.commit('SET_LOADING', false);
        });
    },
  },
  mutations: {
    SET_PUBLIC_NFT_LIST_DATA(state, payload) {
      // console.log('SET_PUBLIC_NFT_LIST_DATA(mutation) =========================== ', payload);
      const filters = {
        state: {},
        attribute: {},
      };

      payload.list.forEach((nft) => {
        nft.attribute = {};
        nft.attributes.forEach((attribute) => {
          nft.attribute[attribute.trait_type] = attribute.value;
        });
      });

      Object.keys(payload.filter.type).forEach((type) => {
        if (type === 'Availability') {
          filters.state[type] = {};
          Object.keys(payload.filter.type[type]).forEach((item) => {
            filters.state[type][item] = {
              name: payload.filter.type[type][item],
              isApply: false,
            }
          });
        } else {
          filters.attribute[type] = {};
          Object.keys(payload.filter.type[type]).forEach((item) => {
            filters.attribute[type][item] = {
              name: payload.filter.type[type][item],
              isApply: false,
            }
          });
        }
      });

      state.publicNFT = {filters, ...payload};
    },

    SET_PUBLIC_NFT_DETAIL_DATA(state, payload) {
      // console.log('SET_PUBLIC_NFT_DETAIL_DATA(mutation) =========================== ', payload);
      state.publicNFTDetail = payload;
    },

  },

}
