import axios from "axios/index";
import qs from 'qs';

export default {
  state: {
    termsOfUse: {},
    servicePolicy: {},
    privacyPolicy: {},
  },
  getters: {
    termsOfUse: state => state.termsOfUse,
    servicePolicy: state => state.servicePolicy,
    privacyPolicy: state => state.privacyPolicy,
  },
  actions: {
    GET_SERVICE_POLICY_DATA({ state }) {
      // console.log('GET_TERMS_SERVICE_POLICY_DATA (action) =========================== ');
      const params = {
        lang: state.locale,
      };
      this.commit('SET_LOADING', true);
      axios
        .post('/apterms/policy/v1.json', qs.stringify(params))
        .then((res) => {
          this.commit('SET_SERVICE_POLICY_DATA', res.data.data);
        })
        .catch((err) => {
          throw Error(`서버 데이터 오류 (GET_SERVICE_POLICY_DATA) => ${err}`);
        })
        .finally(() => {
          this.commit('SET_LOADING', false);
        });
    },
    GET_PRIVACY_POLICY_DATA({ state }) {
      // console.log('GET_PRIVACY_POLICY_DATA (action) =========================== ');
      const params = {
        lang: state.locale,
      };
      this.commit('SET_LOADING', true);
      axios
        .post('/apterms/privacy/v1.json', qs.stringify(params))
        .then((res) => {
          this.commit('SET_PRIVACY_POLICY_DATA', res.data.data);
        })
        .catch((err) => {
          throw Error(`서버 데이터 오류 (GET_PRIVACY_POLICY_DATA) => ${err}`);
        })
        .finally(() => {
          this.commit('SET_LOADING', false);
        });
    },
    GET_TERMS_OF_USE_DATA({ state }) {
      // console.log('GET_TERMS_OF_USE_DATA (action) =========================== ');
      const params = {
        lang: state.locale,
      };
      this.commit('SET_LOADING', true);
      axios
        .post('/apterms/terms/v1.json', qs.stringify(params))
        .then((res) => {
          this.commit('SET_TERMS_OF_USE_DATA', res.data.data);
        })
        .catch((err) => {
          throw Error(`서버 데이터 오류 (GET_TERMS_OF_USE_DATA) => ${err}`);
        })
        .finally(() => {
          this.commit('SET_LOADING', false);
        });
    },
  },
  mutations: {
    SET_SERVICE_POLICY_DATA(state, payload) {
      // console.log('SET_SERVICE_POLICY_DATA(mutation) =========================== ', payload);
      state.servicePolicy = payload;
    },
    SET_PRIVACY_POLICY_DATA(state, payload) {
      // console.log('SET_PRIVACY_POLICY_DATA(mutation) =========================== ', payload);
      state.privacyPolicy = payload;
    },
    SET_TERMS_OF_USE_DATA(state, payload) {
      // console.log('SET_TERMS_OF_USE_DATA(mutation) =========================== ', payload);
      state.termsOfUse = payload;
    },
  },
};
