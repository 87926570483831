import Vue from "vue";
import VueRouter from "vue-router";

//  Navbar
import MainNavbar from "../layout/MainNavbar.vue";
import MainFooter from "../layout/MainFooter.vue";

const isDomestic = JSON.parse(window.__ANIPANG_CLUB_SERVICE_ENV__.base.isDomestic);

Vue.use(VueRouter);

let routes = [
  {
    path: "/",
    name: "Index",
    category: null,
    components: {
      default: () => import('../pages/Index.vue'),
      header: MainNavbar,
      footer: MainFooter
    },
    props: {
      header: { colorOnScroll: 40, type:'anipang', navbarTransparent: true }
    }
  },
  {
    path: "/project",
    name: "PROJECT",
    category: 'Project',
    // category: isDomestic ? null : 'Games',
    components: {
      default: () => import('../pages/project/Index.vue'),
      header: MainNavbar,
      footer: MainFooter
    },
    props: {
      header: { colorOnScroll: 50, type:'anipang', navbarTransparent: true }
    }
  },
  {
    path: "/games/pre-register",
    name: "PRE-REGISTER",
    category: 'Games',
    // category: isDomestic ? null : 'Games',
    components: {
      default: () => import('../pages/games/PreRegister.vue'),
      header: MainNavbar,
      footer: MainFooter
    },
    props: {
      header: { colorOnScroll: 50, type:'anipang', navbarTransparent: true }
    }
  },
  {
    path: "/games/blast",
    name: "Blast",
    // category: null,
    // category: 'Games',
    category: isDomestic ? null : 'Games',
    components: {
      default: () => import('../pages/games/Blast.vue'),
      header: MainNavbar,
      footer: MainFooter
    },
    props: {
      header: { colorOnScroll: 100, type:'anipang', navbarTransparent: true }
    }
  },
  {
    path: "/games/match",
    name: "Match",
    // category: null,
    // category: 'Games',
    category: isDomestic ? null : 'Games',
    components: {
      default: () => import('../pages/games/Match.vue'),
      header: MainNavbar,
      footer: MainFooter
    },
    props: {
      header: { colorOnScroll: 100, type:'anipang', navbarTransparent: true }
    }
  },
  {
    path: "/games/coins",
    name: "Coins",
    // category: null,
    // category: 'Games',
    category: isDomestic ? null : 'Games',
    components: {
      default: () => import('../pages/games/Coins.vue'),
      header: MainNavbar,
      footer: MainFooter
    },
    props: {
      header: { colorOnScroll: 100, type:'anipang', navbarTransparent: true }
    }
  },
  {
    path: "/shop-staking",
    name: "SHOP&STAKING",
    category: 'SHOP&STAKING',
    // category: isDomestic ? null : 'Games',
    components: {
      default: () => import('../pages/market/Intro.vue'),
      header: MainNavbar,
      footer: MainFooter
    },
    props: {
      header: { colorOnScroll: 50, type:'anipang', navbarTransparent: true }
    }
  },
  {
    path: "/NFT/story",
    name: "story",
    // category: 'NFT',
    category: null,
    components: {
      default: () => import('../pages/nft/Story.vue'),
      header: MainNavbar,
      footer: MainFooter
    },
    props: {
      header: { colorOnScroll: 100, type:'anipang', navbarTransparent: true }
    }
  },
  {
    path: "/NFT/schedule",
    name: "Schedule",
    // category: 'NFT',
    category: null,
    components: {
      default: () => import('../pages/nft/Schedule.vue'),
      header: MainNavbar,
      footer: MainFooter
    },
    props: {
      header: { colorOnScroll: 100, type:'anipang', navbarTransparent: true }
    }
  },
  {
    path: "/NFT/minting",
    name: "Minting",
    // category: 'NFT',
    category: null,
    components: {
      default: () => import('../pages/nft/Minting.vue'),
      header: MainNavbar,
      footer: MainFooter
    },
    props: {
      header: { colorOnScroll: 100, type:'anipang', navbarTransparent: true }
    }
  },
  {
    path: "/NFT/gallery",
    name: "Gallery",
    // category: 'NFT',
    category: null,
    components: {
      default: () => import('../pages/nft/Gallery.vue'),
      header: MainNavbar,
      footer: MainFooter
    },
    props: {
      header: { colorOnScroll: 100, type:'anipang', navbarTransparent: true }
    }
  },
  {
    path: "/NFT/detail/:id",
    name: "Detail",
    category: null,
    components: {
      default: () => import('../pages/nft/Detail.vue'),
      header: MainNavbar,
      footer: MainFooter
    },
    props: {
      header: { colorOnScroll: 100, type:'anipang', navbarTransparent: true }
    }
  },
  {
    path: "/roadmap",
    name: "RoadMap",
    category: 'ROAD MAP',
    components: {
      default: () => import('../pages/roadmap/RoadMap.vue'),
      header: MainNavbar,
      footer: MainFooter
    },
    props: {
      header: { colorOnScroll: 100, type:'anipang', navbarTransparent: true }
    }
  },
  {
    path: "/news/notice",
    name: "Notice",
    category: "NEWS",
    components: {
      default: () => import('../pages/news/Notice.vue'),
      header: MainNavbar,
      footer: MainFooter
    },
    props: {
      header: { colorOnScroll: 100, type:'anipang', navbarTransparent: true }
    }
  },
  {
    path: "/news/event",
    name: "Event",
    category: "NEWS",
    components: {
      default: () => import('../pages/news/Event.vue'),
      header: MainNavbar,
      footer: MainFooter
    },
    props: {
      header: { colorOnScroll: 100, type:'anipang', navbarTransparent: true }
    }
  },
  {
    path: "/news/faq",
    name: "FAQ",
    category: "NEWS",
    components: {
      default: () => import('../pages/news/FAQ.vue'),
      header: MainNavbar,
      footer: MainFooter
    },
    props: {
      header: { colorOnScroll: 100, type:'anipang', navbarTransparent: true }
    }
  },
  {
    path: "/mypage",
    name: "MyPage",
    // category: "MyPage",
    category: null,
    components: {
      default: () => import('../pages/mypage/MyPage.vue'),
      header: MainNavbar,
      footer: MainFooter
    },
    props: {
      header: { colorOnScroll: 100, type:'anipang', navbarTransparent: true }
    }
  },
  {
    path: "/termsofuse",
    name: "TermsOfUse",
    category: null,
    components: {
      default: () => import('../pages/terms/TermsOfUse.vue'),
      header: MainNavbar,
      footer: MainFooter
    },
    props: {
      header: { colorOnScroll: 100, type:'anipang', navbarTransparent: true }
    }
  },
  {
    path: "/privacypolicy",
    name: "PrivacyPolicy",
    category: null,
    components: {
      default: () => import('../pages/terms/PrivacyPolicy.vue'),
      header: MainNavbar,
      footer: MainFooter
    },
    props: {
      header: { colorOnScroll: 100, type:'anipang', navbarTransparent: true }
    }
  },
  {
    path: "/servicepolicy",
    name: "ServicePolicy",
    category: null,
    components: {
      default: () => import('../pages/terms/ServicePolicy.vue'),
      header: MainNavbar,
      footer: MainFooter
    },
    props: {
      header: { colorOnScroll: 100, type:'anipang', navbarTransparent: true }
    }
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if ( to.hash ) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    }
    return {
      x: 0,
      y: 0,
      behavior: 'smooth',
    }
  }
});

export default router