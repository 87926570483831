<template>
  <nav id="ac-vertical-nav"
       class="vertical-nav-active">
    <ul>
      <li>
        <a href="javascript:void(0)"
           data-number="1"
           @click="scrollToElement('ac-story')">
          <span class="cd-dot" />
          <span class="cd-label">Story</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
  import Mixins from "@/plugins/basicMixins";

  export default {
    name: "ACVerticalNav",
    mixins: [Mixins.ACVerticalNav],

    mounted() {
      this.leafActive();
      window.addEventListener("resize", this.leafActive);
      document.addEventListener("scroll", this.scrollListener);
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.leafActive);
      document.removeEventListener("scroll", this.scrollListener);
    },
    methods: {
      leafActive() {
        if (window.innerWidth < 768) {
          this.leafShow = false;
        } else {
          this.leafShow = true;
        }
      }
    },
  }
</script>

<style scoped>

</style>