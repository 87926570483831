import qs from 'qs';
import wemix from '@/assets/sdk/wemix'
import environment from '@/assets/sdk/wemix.environment'
import axios from 'axios'
import web3Utils from 'web3-utils'

let target = process.env.VUE_APP_WEMIX_SDK_TARGET || "production";
const env = environment[target];
const stakingApi = {
  PrepareWemixQR: '/wallet/prepare/v1.json',
  WemixQRAccessToken: '/wallet/accessToken/v1.json',
  unsignedTx: '/wallet/logout/v1.json',
  signedTx: '/trade/ft/transaction/v1.json',
};

// setStorage 메소드를 사용하여 localStorage, sessionStorage 를 선택할 수 있습니다. 두번째 인자는 토큰이 저장될 key이름 입니다.
// change sessionstorage eg. wemix.setStorage(sessionStorage,"wemix_ac2_token");

wemix.setStorage(localStorage,"wemix_ac_token");
wemix.setEnv(environment[target]);

const onlyDevPreparedFunc = async function(param){

  // 아래 로직은 client_secret 데이터를 보호하기 위해 서버 사이드에 구현이 되어야 합니다.
  // 테스트용으로만 사용 하시길 강력 권장 드립니다.
  alert("테스트용 function으로 구동 되었습니다. wemix.setRequestPreparedFunction을 구현하세요.");

  const client_secret = process.env.WEMIX_SDK_CLIENT_SECRET;
  return await axios.post(env.oauth+"/api/v2/a2a/prepare",{
    ...param,client_secret
  });
}

const productionPreparedFunc = async function(param){
  // TODO: client_id, type을 받아 wemix oauth api ( /api/v2/a2a/prepare ) 를 호출해 request_id를 받아 오는 로직을 서버 사이드에 구현 하고
  // 구현하신 endpoint로 호출 하시면 됩니다.
  // onlyDevPreparedFunc를 서버 사이드에 구현한다고 생각하시면 됩니다.
  return await axios
    .post(stakingApi.PrepareWemixQR, qs.stringify(param))
    .then(payload => {
      payload.data = payload.data.data;
      return payload;
    })
    .catch(error => {
      throw Error(`서버 데이터 오류 (productionPreparedFunc) => ${error}`);
    });
}

const onlyDevTokenFunc = async function(param){
  // 아래 로직은 client_secret 데이터를 보호하기 위해 서버 사이드에 구현이 되어야 합니다.
  // 테스트용으로만 사용 하시길 강력 권장 드립니다.
  alert("테스트용 function으로 구동 되었습니다. wemix.setRequestTokenFunction을 구현하세요.");

  const client_secret = process.env.WEMIX_SDK_CLIENT_SECRET;
  return axios.post(env.oauth+"/api/v2/oauth/token",{
    ...param,
    client_secret
  })
}

const productionTokenFunc = async function(param){
  // TODO: QR코드 인증 시 리턴되는 code가 param에 담깁니다. 이를 통해 wemix oauth api ( /api/v2/oauth/token ) 를 호출해 auccessToken 를 받아 오는 로직을 서버 사이드에 구현 하고
  // 구현하신 endpoint로 호출 하시면 됩니다.
  // onlyDevTokenFunc 서버 사이드에 구현한다고 생각하시면 됩니다.
  return await axios
    .post(stakingApi.WemixQRAccessToken, qs.stringify(param))
    .then(payload => {
      payload.data = payload.data.data;
      return payload;
    })
    .catch(error => {
      throw Error(`서버 데이터 오류 (productionTokenFunc) => ${error}`);
    });
}

const onlyDevMakeUnsignedTx = async function(param){
  console.log("개발용 makeUnsignedTx를 이용해 만들어진 hash 입니다. wallet-server의 unsignedTx api는 테스트용으로만 사용 바랍니다.")
  return await axios.post(this.env().wallet+"/account/unsignedTx", param);
}

const productionMakeUnsignedTx = async function(param){
  return await axios
    .post(stakingApi.unsignedTx, qs.stringify(param))
    .then(payload => {
      payload.data = payload.data.data;
      return payload;
    })
    .catch(error => {
      throw Error(`서버 데이터 오류 (productionMakeUnsignedTx) => ${error}`);
    });
}

const onlyDevSendSignedTx = async function(hash,signature,type){
  console.log("개발용 sendSignedTx 이용해 만들어진 hash 입니다. wallet-server의 signedTx api는 테스트용으로만 사용 바랍니다.")
  /*
  if (type ==2 ){
      let last = signature.substring(signature.length-2,signature.length);
      let cal = wemix().dec2hex(wemix().hex2dec(last)+27);
      signature = signature.substring(0,signature.length-2)+cal
  }
  */
  return await axios.post(this.env().wallet+"/account/signedTx",{hash,sign:signature,type});
}

const productionSendSignedTx = async function(hash,signature,type){
  const message = {
    signature,
    hash,
  };

  if (type === 3) {
    return await axios.post("/apmint/send/transaction/v1.json", qs.stringify(message));
  }
  hash.message.forEach((item) => {
    if (item.name === 'column') {
      message[item.name] = 'store';
    } else {
      message[item.name] = item.value;
    }
  });

  message['method'] = hash.method;

  return await axios
    .post(stakingApi.signedTx, qs.stringify(message))
    .then(payload => {
      payload.data = payload.data.data;
      return payload;
    })
    .catch(error => {
      throw Error(`서버 데이터 오류 (productionSendSignedTx) => ${error}`);
    });
}

// TODO: 구현 후 삭제
// wemix.setRequestPreparedFunction(onlyDevPreparedFunc);
// wemix.setRequestTokenFunction(onlyDevTokenFunc);
// wemix.setRequestMakeUnsignedTx(onlyDevMakeUnsignedTx);
// wemix.setRequestSendSignedTx(onlyDevSendSignedTx);

// TODO: 구현 후 주석 해제
wemix.setRequestPreparedFunction(productionPreparedFunc);
wemix.setRequestTokenFunction(productionTokenFunc);
wemix.setRequestMakeUnsignedTx(productionMakeUnsignedTx);
wemix.setRequestSendSignedTx(productionSendSignedTx);

// Sample 작성을 위해 글로벌 변수로 작성했습니다.
// 개발 시 에는 import 받아서 활용 바랍니다.
window.wemix = function() {return wemix};
window.web3 = web3Utils;
export default wemix;