import axios from './axios';
import Wemix from './wemix.base'
import './wemix.qr'
import './wemix.tx'
import './wemix.view'
import web3Utils from 'web3-utils'
Wemix.prototype.setStorage=function(storage,key){
    this.storage().storage = storage;
    this.storage().key = key;
}
Wemix.prototype.getStorage=function(){
    return this.storage().storage;
}
Wemix.prototype.setEnv=function(env){    

    Object.keys(env).forEach(k=>{
        this.env()[k]=env[k]
    })

    //this.env = env;    
    if(this.storage().storage==null){
        console.info("you can set wemix storage with setStorage(storage,key) function");
        return;
    }

    if(this.storage().getKeyValue()!=null){
        axios.setAccessToken(this.storage(),this.storage().getKeyValue());
        this.login("ko");
    }
}

Wemix.prototype.verify=async function(){
    return await axios.post(this.env().wallet+"/verify");
}

Wemix.prototype.login = async function(lang){
    if(lang==null){
        lang="en";
    }
    const task = axios.post(this.env().wallet+"/login", { lang });
    
    task.then(result => {
        this.address=result.data.address;
        this.tokens=[];
        result.data.tokens.forEach((row) => {
            this.tokens[row.symbol] = row;
        });
    });
    return await task;
}

Wemix.prototype.token = function(symbol){
    return this.tokens[symbol];        
}
Wemix.prototype.tokenAll = function(){
    return this.tokens;        
}

Wemix.prototype.balance = async function(symbol){   
   return await axios.post(this.env().wallet+"/balance/balance", { symbol, owner: "0x0000000000000000000000000000000000000000" })
}

Wemix.prototype.balanceAll = async function(){   
   return await axios.post(this.env().wallet+"/balance/balanceAll", { })
}

Wemix.prototype.priceAll = async function(){    
    return await axios.post(this.env().wallet+"/price/all", { })
}
Wemix.prototype.utils=function(){
    return web3Utils;
}
const wemix = new Wemix();

export default wemix;