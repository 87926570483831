import axios from "axios/index";
import qs from 'qs';

export default {
  state: {
    isWemixLoggedIn: false,
    isWemixAgree: null,
    user: {},
  },
  getters: {
    isWemixLoggedIn: state => state.isWemixLoggedIn,
    isWemixAgree: state => state.isWemixAgree,
    user: state => state.user,
  },
  actions: {
    GET_LOGIN_USER_DATA() {
      // console.log('GET_LOGIN_USER_DATA (action) =========================== ');
      axios
        .get('/user/v1.json', {
          params: {},
        })
        .then((res) => {
          const payload = res.data.data;
          const userData = {...payload, isAgree: payload['login']};

          this.commit('INITIALIZE_USER', userData);
        })
        .catch((err) => {
          throw Error(`서버 데이터 오류 (getGlobalInfoAPI) => ${err}`);
        });
    },

    SUBMIT_LOG_OUT() {
      // console.log('SUBMIT_LOG_OUT (action) =========================== ');
      axios
        .get('/user/logout/v1.json', {
          params: {},
        })
        .then((res) => {
          const payload = res.data.data;

          if (payload.success) {
            this.commit('SET_WEMIX_SIGN', false);
          }
        })
        .catch((err) => {
          throw Error(`서버 데이터 오류 (getGlobalInfoAPI) => ${err}`);
        });
    },

    SUBMIT_WALLET_LOGIN_DATA(state, payload) {
      // console.log('SUBMIT_WALLET_LOGIN_DATA (action) =========================== ', payload);
      const params = {
        accessToken: state.state.wemix.getStorage().wemix_ac_token,
        userAddress: payload.address,
      };

      axios
        .post('/wallet/login/v1.json',  qs.stringify(params))
        .then((payload) => {
          if (payload.data.data && payload.data['data'] && payload.data['data']['success'] === true) {
            this.commit('SET_WEMIX_SIGN', params);
            this.commit('SET_USER_WALLET_DATA', params);
          } else {
            this.commit('SET_WEMIX_AGREE', false);
          }
        })
        .catch((err) => {
          throw Error(`서버 데이터 오류 (getWemixSignAPI) => ${err}`);
        });
    },

    SUBMIT_JOIN(state, payload) {
      // console.log('SUBMIT_JOIN (action) =========================== ', payload);
      const params = {
        accessToken: state.state.wemix.getStorage().wemix_ac_token,
        userAddress: payload.address,
      };

      axios
        .post('/wallet/join/v1.json',  qs.stringify(params))
        .then((payload) => {
          if (payload.data.data && payload.data['data'] && payload.data['data']['success'] === true) {
            // 이용동의 완료 회원
            this.commit('SET_WEMIX_SIGN', params);
            this.commit('SET_USER_WALLET_DATA', params);
          }
        })
        .catch((err) => {
          throw Error(`서버 데이터 오류 (SUBMIT_JOIN) => ${err}`);
        });
    },
  },
  mutations: {
    INITIALIZE_USER(state, payload) {
      // console.log('INITIALIZE_USER (action) =========================== ', payload);
      state.isWemixLoggedIn = payload.walletAddr? true : false;
      state.user = payload;
    },

    SET_WEMIX_AGREE( state, payload) {
      // console.log('SET_WEMIX_AGREE(mutation) =========================== ', payload);
      state.isWemixAgree = payload;
    },

    SET_WEMIX_SIGN( state, payload) {
      // console.log('SET_WEMIX_SIGN(mutation) =========================== ', payload);
      if (!payload) {
        localStorage.removeItem('wemix_ac_token');
        state.user.walletAddr = '';
        state.user.login = false;
        state.user.isAgree = false;
        state.myNFT = {};
      }
      state.isWemixLoggedIn = payload ? true : false;
    },

    SET_USER_WALLET_DATA( state, payload) {
      // console.log('SET_USER_WALLET_DATA(mutation) =========================== ', payload);
      const aaa = qs.parse(payload);

      state.user.walletAddr = aaa.userAddress;
      state.wemixAddress = aaa.userAddress;
    },
  },

}