<template>
  <div>
    <div v-for="(item, index) in collapse"
         :key="index"
         class="ac-event"
         :class="[
            'md-collapse',
            activeCollapse(index + 1),
            { [getColorCollapse(colorCollapse)]: true }
          ]">
      <div class="md-collapse-label"
           @click="toggle(index + 1)">
        <h5 class="md-collapse-title">

          <strong class="ac-event-title">
            {{ item.title }}
          </strong>
          <span class="ac-notice-date">
            {{ item.date }}
          </span>
          <md-icon>{{ icon }}</md-icon>
        </h5>
      </div>

      <collapse-transition :duration="350">
        <div v-show="getActiveCollapse(index + 1)"
             class="md-collapse-content">
          <slot :name="getCollapseContent(index + 1)" />
        </div>
      </collapse-transition>
    </div>
  </div>
</template>

<script>
import { CollapseTransition } from "vue2-transitions";

export default {
  name: "Collapse",
  components: {
    CollapseTransition
  },
  props: {
    collapse: Array,
    icon: String,
    colorCollapse: String,
    activeTab: Number
  },
  data() {
    return {
      isActive: this.activeTab
    };
  },
  methods: {
    getActiveCollapse(index) {
      return this.isActive == index;
    },
    activeCollapse(index) {
      return {
        "is-active": this.isActive == index
      };
    },
    toggle(index) {
      if (index == this.isActive) {
        this.isActive = 0;
      } else {
        this.isActive = index;
      }
    },
    getCollapseContent: function(index) {
      return "md-collapse-pane-" + index + "";
    },
    getColorCollapse: function(colorCollapse) {
      return "md-" + colorCollapse + "";
    }
  }
};
</script>

<style lang="scss">

  .ac-event {
    .ac-notice-category {
      background: rgb(75,42,144);
      background: linear-gradient(180deg, rgba(75,42,144,1) 0%, rgba(117,69,212,1) 100%);
    }

    .md-collapse-label {
      padding: 5px 10px 5px 0;

      .md-collapse-title {
        margin: 0;
        color: #666 !important;

        .ac-event-title {
          color: #cdabff;
          display: inline-flex;
          font-weight: 800;
          font-size: 20px;
          line-height: 1.4em;
          padding: 10px 30px 10px 10px;
        }

        .ac-notice-date {
          position: absolute;
          right: 40px;
          display: none;
        }

        &:hover,
        &:focus {
          color: #6535b3 !important;
        }
        .md-icon {
          top: 16px;
          color: #6535b3 !important;
        }
      }
    }

    .md-collapse-content {
      font-weight: 300;
      padding: 20px 15px;
      font-size: 15px;
      color: #ffffff;
    }

    &.is-active {
      .md-collapse-label {
        .md-collapse-title {
          color: #6535b3 !important;

          &,
          &:hover,
          &:focus {
            color: #6535b3 !important;
          }
          .md-icon {
            color: #6535b3 !important;
          }
        }
      }
    }
  }



  @media (min-width: 992px) {

    .ac-event {
      .md-collapse-label {

        .md-collapse-title {
          margin: 0;

          .ac-notice-date {
            position: absolute;
            right: 40px;
            top: 16px;
            display: inline-block;
          }
        }
      }
    }
  }
</style>
