import axios from "axios/index";
import qs from 'qs';

export default {
  state: {
    locale: '',
    isLoading: false,
    isToast: false,
    message: {
      toast: {},
      alert: {},
      confirm: {},
      notification: {},
    },
  },
  getters: {
    locale: state => state.locale,
    isLoading: state => state.isLoading,
    isToast: state => state.isToast,
    // message
    messageToast: state => state.message.toast,
    messageAlert: state => state.message.alert,
    messageConfirm: state => state.message.confirm,
    messageNotification: state => state.message.notification,
  },
  actions: {
    GET_LANGUAGE_DATA({ state }, payload) {
      // console.log('GET_LANGUAGE_DATA (action) =========================== ', payload);
      const params = {
        locale: payload ? payload : navigator.language.split('-')[0],
      };

      axios
        .post('/resource/locale/v1.json', qs.stringify(params))
        .then((res) => {
          const payload = res.data.data;

          this.commit('SET_LOCALE_DATA', payload.locale);
          this.commit('SET_LANGUAGE', payload.language);
        })
        .catch((err) => {
          throw Error(`서버 통신 오류 (GET_LANGUAGE_DATA) => ${err}`);
        });
    },
  },
  mutations: {
    SET_LOCALE_DATA(state, payload) {
      // console.log('SET_LOCALE_DATA (action) =========================== ', payload);
      state.locale = payload;
    },
    SET_LANGUAGE(state, payload) {
      // console.log('SET_LANGUAGE (mutation) =========================== ', payload);
      state.language = payload;
    },

    // loading
    SET_LOADING(state, payload) {
      // console.log('SET_LOADING_START (mutation) =========================== ', payload);
      state.isLoading = payload;
    },

    SET_TOAST(state, payload) {
      // console.log('SET_TOAST (mutation) =========================== ', payload);
      state.isToast = true;
      const wait = 2000;
      const timeout = setTimeout(() => {
        state.isToast = false;
      }, wait);
    },

    // 사용자 알림
    SET_MESSAGE(state, payload) {
      // console.log('SET_MESSAGE(mutation) =========================== ', payload);
      state.message[payload.method] = payload;
    },
  },
};
