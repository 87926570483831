import axios from "axios/index";
import qs from 'qs';

export default {
  state: {
    minting_info: {},
    minting_user: {},
    minted_NFT: {},
    minted_error: {},
    is_possible_minting_status: false,
  },
  getters: {
    minting_info: state => state.minting_info,
    minting_user: state => state.minting_user,
    minted_NFT: state => state.minted_NFT,
    minted_error: state => state.minted_error,
    is_possible_minting_status: state => state.is_possible_minting_status,
  },
  actions: {
    GET_MINTING_INFO_DATA({ state }, payload) {
      // console.log('GET_MINTING_INFO_DATA (action) =========================== ', payload);
      let params = {};

      this.commit('SET_LOADING', true);
      axios
        .post('/apmint/event/v1.json', qs.stringify(params))
        .then((res) => {
          const payload = res.data.data;

          if (payload) {
            this.commit('SET_MINTING_INFO_DATA', payload);
          } else {
            alert('GET_MINTING_INFO_DATA 잘못됨');
          }
        })
        .catch((err) => {
          throw Error(`서버 데이터 오류 (getGlobalInfoAPI) => ${err}`);
        })
        .finally(() => {
          this.commit('SET_LOADING', false);
        });
    },

    GET_MINTING_USER_DATA({ state }, payload) {
      // console.log('GET_MINTING_USER_DATA (action) =========================== ', payload);
      let params = {
        walletAddr: payload,
      };

      this.commit('SET_LOADING', true);
      axios
        .post('/apmint/init/v1.json', qs.stringify(params))
        .then((res) => {
          const payload = res.data.data;

          this.commit('SET_MINTING_USER_DATA', payload);
        })
        .catch((err) => {
          throw Error(`서버 데이터 오류 (getGlobalInfoAPI) => ${err}`);
        })
        .finally(() => {
          this.commit('SET_LOADING', false);
        });
    },

    SUBMIT_MINTING_DATA({ state }, payload) {
      // console.log('SUBMIT_MINTING_DATA (action) =========================== ', payload);
      let params = {};
      
      this.commit('SET_LOADING', true);
      axios
        .post('/apmint/make/transaction/v1.json', qs.stringify(params))
        .then((res) => {
          const result = res.data.data;

          if(result['success']) {
            let req = state.wemix.requestSignatureWithMemo(['NFT Mint'], ['yellow'], [result.hash]);

            state.wemix.openQR('sign', req,
              success => {
                this.dispatch('WAIT_MINTING_RESULT', success[0]['data']['txhash'])
                // txType = mint , approve
              },
              fail => {
                this.commit('SET_MINTED_ERROR', fail[0]['data']['error']);
                alert(fail[0]['data']['error']);
              })
          } else if(result['error']) {
            this.commit('SET_MINTED_ERROR', result.error);
          } else {
            alert('SUBMIT_MINTING_DATA 잘못됨');
          }
        })
        .catch((err) => {
          throw Error(`서버 데이터 오류 (SUBMIT_MINTING_DATA) => ${err}`);
        })
        .finally(() => {
          this.commit('SET_LOADING', false);
        });
    },


    SUBMIT_APPROVE_DATA({ state }, payload) {
      // console.log('SUBMIT_APPROVE_DATA (action) =========================== ', payload);
      let params = {};

      this.commit('SET_LOADING', true);
      axios
        .post('/apmint/make/approve/request/v1.json', qs.stringify(params))
        .then((res) => {
          const result = res.data.data;

          if(result['success']) {
            let req = state.wemix.requestSignatureWithMemo(['NFT Mint'], ['yellow'], [result.hash]);

            state.wemix.openQR('sign', req,
              success => {
                // this.dispatch('APPROVE_RESULT', req);
                this.dispatch('GET_MINTING_USER_DATA');
              },
              fail => {
                this.commit('SET_MINTED_ERROR', fail[0]['data']['error']);
                alert(fail[0]['data']['error']);
              })
          } else if(result['error']) {
            this.commit('SET_MINTED_ERROR', result.error);
          } else {
            alert('SUBMIT_MINTING_DATA 잘못됨');
          }
        })
        .catch((err) => {
          throw Error(`서버 데이터 오류 (SUBMIT_MINTING_DATA) => ${err}`);
        })
        .finally(() => {
          this.commit('SET_LOADING', false);
        });
    },

    APPROVE_RESULT({ state }, payload) {
      // console.log('APPROVE_RESULT (action) =========================== ', payload);
      this.dispatch('GET_MINTING_USER_DATA');

    },

    WAIT_MINTING_RESULT({ state }, payload) {
      // console.log('WAIT_MINTING_RESULT (action) =========================== ', payload);
      this.commit('SET_LOADING', true);
      this.commit('SET_POSSIBLE_MINTING_STATUS', false);
      axios
        .post('/apmint/receipt/transaction/v1.json', qs.stringify({"txhash" : payload}))
        .then((res) => {
          const result = res.data.data;

          if(result['status'] === 0) {
            alert('WAIT_MINTING_RESULT 잘못됨');
          }else if(result['status'] === 4) {
            this.dispatch('GET_MINTING_INFO_DATA');
            this.commit('SET_MINTED_NFT_DATA', result.nft);
          }else {
            setTimeout(() => {
              this.dispatch('WAIT_MINTING_RESULT', payload);
            }, 1000);
          }


        })
        .catch((err) => {
          throw Error(`서버 통신 오류 (WAIT_MINTING_RESULT) => ${err}`);
        })
        .finally(() => {
          this.commit('SET_LOADING', false);
        });
    },
  },
  mutations: {
    SET_MINTING_INFO_DATA(state, payload) {
      // console.log('SET_MINTING_INFO_DATA(mutation) =========================== ', payload);

      state.minting_info = payload;
    },

    SET_MINTING_USER_DATA(state, payload) {
      // console.log('SET_MINTING_USER_DATA(mutation) =========================== ', payload);

      state.minting_user = payload;
    },

    SET_MINTED_NFT_DATA(state, payload) {
      // console.log('SET_MINTED_NFT_DATA(mutation) =========================== ', payload);

      state.minted_NFT = payload;
    },

    SET_MINTED_ERROR(state, payload) {
      // console.log('SET_MINTED_ERROR(mutation) =========================== ', payload);

      state.minted_error = payload;
    },

    SET_POSSIBLE_MINTING_STATUS(state, payload) {
      // console.log('SET_POSSIBLE_MINTING_STATUS(mutation) =========================== ', payload);

      state.is_possible_minting_status = payload;
    },
  },
}
