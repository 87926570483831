import Vue from 'vue';
import Vuex from 'vuex';
import CreatePersistedState from 'vuex-persistedstate';

import base from './spec/base';
import wemix from './spec/wemix';

import basic from './basic';
import login from './login';

import FT from './FT';
import NFT from './NFT';
import minting from './minting';
import board from './board';
import terms from './terms';

Vue.use(Vuex);


let resizeTimeout;
window.resizeThrottler = (actualResizeHandler) => {
  // ignore resize events as long as an actualResizeHandler execution is in the queue
  if (!resizeTimeout) {
    resizeTimeout = setTimeout(() => {
      resizeTimeout = null;
      actualResizeHandler();

      // The actualResizeHandler will execute at a rate of 15fps
    }, 66);
  }
}


const vuexSessionPersisted = new CreatePersistedState({
  key: 'anipang-club-session',
  storage: window.sessionStorage,
  reducer: states => ({
    member: states.member,
    ordering: states.ordering,
  }),
});

export default new Vuex.Store({
  state: {
    base,
    language: {},
    wemix,

    ...basic.state,
    ...login.state,
    ...FT.state,
    ...NFT.state,
    ...minting.state,
    ...board.state,
    ...terms.state,
  },

  getters: {
    base: state => state.base,
    language: state => state.language,
    wemix: state => state.wemix,
    ...basic.getters,
    ...login.getters,
    ...FT.getters,
    ...NFT.getters,
    ...minting.getters,
    ...board.getters,
    ...terms.getters,
  },

  actions: {
    ...basic.actions,
    ...login.actions,
    ...FT.actions,
    ...NFT.actions,
    ...minting.actions,
    ...board.actions,
    ...terms.actions,

    INITIALIZE_APP() {
      this.dispatch('GET_LANGUAGE_DATA');
      this.dispatch('GET_LOGIN_USER_DATA');
    },
  },

  mutations: {
    ...basic.mutations,
    ...login.mutations,
    ...FT.mutations,
    ...NFT.mutations,
    ...minting.mutations,
    ...board.mutations,
    ...terms.mutations,
  },


  plugins: [vuexSessionPersisted],
});
