import Wemix from './wemix.base'
import i18n from './language/i18n'
import html from './templete/qr/qr.html'
import mobileHtml from './templete/qr/mobile/mobile.html'
import axios from './axios';
import qrcode from 'qrcode';
import Device from './device';


Wemix.prototype.openQR=async function(type,req,success,fail){

    const templetes = ["qr.title.prefix","qr.title.suffix","qr.remain","qr.text1","qr.text2","qr.step1","qr.step2","qr.step3","qr.step1.mobile","qr.step2.mobile","qr.step3.mobile","qr.other1","qr.allow.popup.mobile"]
    let bind = html;

    if(Device.isMobile()){
        bind = mobileHtml;
        require('./templete/qr/mobile/mobile.css')
    } else {
        require('./templete/qr/qr.css')
    }   

    templetes.forEach((element) => {
        const v = i18n.t(element)        
        bind = bind.replace("{"+element+"}",v)
    });

    document.body.insertAdjacentHTML("afterend",bind)

    let self = this;
    let modal = document.querySelector(".wemix__modal");
    let canvas = document.querySelector(".wemix__qr-canvas");
    let btnClose = document.querySelector(".wemix__modal-close");
    let remainSec = document.querySelector(".wemix__modal-qrcode-remain");
    let refreshIcon = document.querySelector(".wemix__modal-qrcode-image-refresh");
    if(refreshIcon!=null){
        refreshIcon.onclick=function(){
            self.getQR().btnClose.click();
            self.openQR(type,req, success, fail);
        }
    }
    btnClose.onclick=function(){
        clearInterval(self.getQR().timer);
        modal.parentNode.removeChild(modal);
    }
    
    const qr={
        canvas:canvas,
        remainSec,
        btnClose,
        req,
        refreshIcon,
    };
    this.getQR=function(){
        return qr;
    };
    const prepare = await this.authPrepare(type,req);  
    if(Device.isMobile()){
            window.open(prepare.url,"WEMIX Authentication");
    }
    qr.request_id  =prepare.request_id;
    qr.expires_in  =prepare.expires_in;
    qr.type = type;
    qr.timer       =this._listenQR(qr,success,fail);
    qr.decreaseRemain = function(sec){
        this.expires_in = this.expires_in-1;
        let second = this.expires_in;
        if (sec!=undefined ){
            second = sec
        }
        if(second>0){
            const mm = parseInt(((second%3600)/60).toString()).toString();
            const ss = parseInt((second%60).toString()).toString();        
            let format = i18n.t("qr.remain.format").replace("${mm}",mm).replace("${ss}",ss);
            this.remainSec.innerText = format;   
        }else{
            this.remainSec.innerText = i18n.t("qr.expired");
            if(refreshIcon!=null)
                //refreshIcon.attributeStyleMap.set("display","block");
                refreshIcon.style.setProperty("display","block");
        }
    }
    qr.decreaseRemain();    
}

Wemix.prototype.qr = {
    canvas:null,
    remainSec:0,
    btnClose:null,
    req:null,
}

Wemix.prototype._listenQR=function(qr, success, fail ){
    let self=this;
    let request_id=qr.request_id;
    const timer=setInterval(function(){                
        self.getQR().decreaseRemain();
        const task = axios.get(self.env().oauth+"/api/v2/a2a/result?client_id="+self.env().client_id+"&request_id="+request_id);

        task.then(function(result){
            const status = result.data.status;

            if(status!=="prepare"&&status!=="pending"&&status!=="consent"){
                clearInterval(timer);
                if(status=="confirm"){
                    if(self.getQR().type==="auth"){

                        const token = self.requestTokenFunction({
                            client_id:self.env().client_id,                            
                            code:result.data.result,
                            grant_type:"code"
                        }).then(c=>{
                            axios.setAccessToken(self.storage(),c.data.access_token);
                            self.login("ko");
                            success(c);
                            self.getQR().btnClose.click();
                        }).catch(err=>{
                            fail(err);
                        });

                    }else{
                        const hashes = self.getQR().req.req.hashData;
                        const types = self.getQR().req.req.type;
                        const signatures = JSON.parse(result.data.result);
                        if(signatures.length!=hashes.length){
                          alert("not match signature and hashes");
                        }
                        const allTask = self.sendSignedTxs(hashes,signatures,types);

                        allTask.then(all=>{
                            let r = true;
                            for(let i = 0;i<all.length;i++){
                                if(all[i].Result!=0){
                                    fail(all);
                                    r = false;
                                }
                            }
                            if(r){
                                success(all);                                                         
                            }
                        }).catch(err=>{
                            fail([],err.toJSON());                            
                        })
                        self.getQR().btnClose.click();
                    }
                }
            } else if (status=="pending"){
                if(qr.refreshIcon!=null)
                    qr.refreshIcon.style.setProperty("display","block");
            }
        }).catch(error=>{                
            clearInterval(timer);
            console.log(error);
            self.getQR().decreaseRemain(0);
            fail(error.toJSON());            
        }); 

    },1000);
    return timer;
}
Wemix.prototype.setRequestTokenFunction=function(func){
    this.requestTokenFunction = func;
}
Wemix.prototype.setRequestPreparedFunction=function(func){
    this.requestPreparedFunction = func;
}
Wemix.prototype.authPrepare=async function(type,req){

    const param = {client_id:this.env().client_id,type};
    
    if(type=="sign"){
        param.data=req.jwt
    }
    const result = await this.requestPreparedFunction(param);   

    let url = this.makeQRValue(result.data.request_id,type,null) +"&client_id="+this.env().client_id;//+"&redirect_uri="+location.href;
  
    /*
        if(type=="sign"){
            url += "&query="+req.jwt
        }
    */
    qrcode.toCanvas(this.getQR().canvas,url,{width:300,errorCorrectionLevel: 'L'});
    
    const authQrResult = {  
        url,
        expires_in:result.data.expires_in,
        request_id:result.data.request_id
    };    
    return authQrResult
}
Wemix.prototype.makeQRValue=function(request_id,type){    
    let path ="";
    if(type=="auth")
        path="authentication"
    else if(type=="sign")
        path="signature"

    return this.env().oauth+"/api/v2/a2a/launch/"+path+"?request_id="+request_id;    
}


Wemix.prototype.dec2hex=function(number){
    let hexString = number.toString(16);    
    return hexString;    
}
Wemix.prototype.hex2dec=function(hexString){    
    let yourNumber = parseInt(hexString, 16);    
    return yourNumber;    
}