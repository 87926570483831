import axios from "axios/index";
import qs from 'qs';

const _getMessageElements = async (item) => await axios.post(item.url, qs.stringify(item.params)).then();

export default {
  state: {
    balance: {},
    exchangeRate: 1,
    contractAddress: '',
    contractNonce: '',
    myNFT: [],
    exchangeHistory: [],
  },
  getters: {
    balance: state => state.balance,
    exchangeRate: state => state.exchangeRate,
    contractAddress: state => state.contractAddress,
    contractNonce: state => state.contractNonce,
    myNFT: state => state.myNFT,
    exchangeHistory: state => state.exchangeHistory,
  },
  actions: {
    GET_MY_NFT_DATA({ state }) {
      // console.log('GET_MY_NFT_DATA (action) =========================== ');
      this.commit('SET_LOADING', true);
      axios
        .get('/apnft/ownTokens/v1.json', {
          params: {},
        })
        .then((res) => {
          const payload = res.data.data;

          this.commit('SET_MY_NFT_DATA', payload);
        })
        .catch((err) => {
          throw Error(`서버 데이터 오류 (getBalanceAPI) => ${err}`);
        })
        .finally(() => {
          this.commit('SET_LOADING', false);
        });
    },

    GET_BALANCE_DATA({ state }) {
      // console.log('GET_BALANCE_DATA (action) =========================== ');
      this.commit('SET_LOADING', true);
      axios
        .get('/user/balance/v1.json', {
          params: {},
        })
        .then((res) => {
          const payload = res.data.data;

          this.commit('SET_BALANCE_DATA', payload);
        })
        .catch((err) => {
          throw Error(`서버 데이터 오류 (getBalanceAPI) => ${err}`);
        })
        .finally(() => {
          this.commit('SET_LOADING', false);
        });
    },

    GET_EXCHANGE_HISTORY_DATA({ state }) {
      // console.log('GET_EXCHANGE_HISTORY_DATA (action) =========================== ');
      this.commit('SET_LOADING', true);
      axios
        .get('/trade/ft/exchange/history/v1.json', {
          params: {},
        })
        .then((res) => {
          const payload = res.data.data;

          this.commit('SET_EXCHANGE_HISTORY_DATA', payload.rate);
        })
        .catch((err) => {
          this.commit('SET_EXCHANGE_HISTORY_DATA', [
            {
              game: '',
              type: '',
              amount: '',
              ingame_item_cnt: '',
              exchange_rate: '',
            },
            {
              game: '',
              type: '',
              amount: '',
              ingame_item_cnt: '',
              exchange_rate: '',
            }
          ]);
          throw Error(`서버 통신 오류 (getBalanceAPI) => ${err}`);
        })
        .finally(() => {
          this.commit('SET_LOADING', false);
        });
    },


    GET_EXCHANGE_RATE_DATA({ state }) {
      // console.log('GET_BALANCE_DATA (action) =========================== ');
      this.commit('SET_LOADING', true);
      axios
        .get('/trade/ft/exchange/rate/v1.json', {
          params: {},
        })
        .then((res) => {
          const payload = res.data.data;

          this.commit('SET_EXCHANGE_RATE_DATA', payload.rate);
        })
        .catch((err) => {
          throw Error(`서버 데이터 오류 (getBalanceAPI) => ${err}`);
        })
        .finally(() => {
          this.commit('SET_LOADING', false);
        });
    },

    GET_EXCHANGE_MESSAGE_DATA(state, params) {
      // console.log('GET_EXCHANGE_MESSAGE_DATA (action) =========================== ');
      const promiseList = [
        {url: '/trade/ft/contract/address/v1.json', params: {}},
        {url: '/trade/ft/contract/nonce/v1.json', params: {user_address: params.user_address}},
      ];

      Promise
        .all(promiseList.map((item) => _getMessageElements(item)))
        .then((payload) => {
          let messageObj = {
            "message": [
              {"type": "address", "value": payload[0].data.data.contract_address, "name": "contract"},
              {"type": "address", "value": params.user_address, "name": "user"},
              {"type": "uint256", "value": payload[1].data.data.nonce, "name": "nonce"},
            ],
            "chain": "tornado",
            "method": params.method,
          };

          if (params.method === 'exchangeToItem') {
            messageObj.message.push({"type": "bytes32", "value": "0x73746f7265000000000000000000000000000000000000000000000000000000", "name": "column"})
          }
          messageObj.message.push({"type": "uint256", "value": state.state.wemix.utils().toWei(params.amount), "name": "amount"});

          let req = state.state.wemix.requestMessageSignature(["store"],["yellow"],messageObj);

          state.state.wemix.openQR("sign",req,
            success=>{
              console.log('success = ', success);
              // const payload = success.data.data;
              //
              // if (payload.success) {
                this.dispatch('GET_BALANCE_DATA');
              // } else {
              //   throw new Error('정상적인 교황네 실패 하였습니다.')
              // }
            },
            fail=>{
              console.log(fail);
              throw new Error("트랜젝션 수행 중 오류가 발생 하였습니다. 개발자 모드의 로그를 확인 바랍니다.", fail);
            });
        })
        .catch((err) => {
          throw Error(`서버 데이터 오류 (getActionLeftCoolTimesAPI) => ${err}`);
        });
    },

    SUBMIT_TRANSFER_ABLE_DATA({ state }, payload) {
      // console.log('SUBMIT_TRANSFER_ABLE_DATA (action) =========================== ', payload);
      this.commit('SET_LOADING', true);
      const params = {
        token_id: payload.token_id,
        isTransferable: payload.locked,
      };

      axios
        .post('/apnft/setTransferable/v1.json', qs.stringify(params))
        .then((res) => {
          const payload = res.data.data;

          if (!payload.success) {
            // throw Error(`locking 설정에 실패하였습니다.`);
          }
          
          this.commit('SET_MY_NFT_DATA', payload.list);
        })
        .catch((err) => {
          this.commit('SET_TOAST');
          throw Error(`서버 데이터 오류 (getBalanceAPI) => ${err}`);
        })
        .finally(() => {
          this.commit('SET_LOADING', false);
        });
    },
  },
  mutations: {
    SET_MY_NFT_DATA(state, payload) {
      // console.log('SET_MY_NFT_DATA(mutation) =========================== ', payload);
      payload.forEach((item) => {
        item.remaining = '00 : 00 : 00';
      });
      state.myNFT = payload;
    },

    SET_BALANCE_DATA(state, payload) {
      // console.log('SET_BALANCE_DATA(mutation) =========================== ', payload);
      const fromWei = state.wemix.utils().fromWei;

      payload.anico = Number(fromWei(payload.anico));
      state.balance = { ...payload };
    },

    SET_EXCHANGE_RATE_DATA(state, payload) {
      // console.log('SET_EXCHANGE_RATE_DATA(mutation) =========================== ', payload);
      state.exchangeRate = payload;
    },

    SET_EXCHANGE_HISTORY_DATA(state, payload) {
      // console.log('SET_EXCHANGE_HISTORY_DATA(mutation) =========================== ', payload);
      state.exchangeHistory = payload;
    },
  },
}
