<template>
  <div class="l-minting aos-init aos-animate"
       data-aos="fade-up"
       data-aos-duration="500"
       data-aos-delay="200">
    <a href="https://anipangsupporter.club"
       class="item"
       target="_blank">
      <div class="img">
        <img :src="floating_banner" alt="슬롯이미지">
      </div>
    </a>
  </div>
</template>

<script>
  import AOS from "aos";
  import "aos/dist/aos.css";

  export default {
    name: "ACBubbleLink",
    created() {
      AOS.init();
    },
    data() {
      return {
        click: require("@/assets/img/pages/index/pre_order.gif"),
        bg: require("@/assets/img/pages/index/pre_order_bg.png"),
        floating_banner: require("@/assets/img/pages/index/floating_banner.png")
      }
    },
  };
</script>

<style lang="scss">
  .l-minting {
    width: 100px;
    position: fixed;
    right: 0;
    transform: translateY(-50%);
    bottom: 45px;
    // left: 10%;
    right: 10%;
    height: 100px;
    z-index: 100;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    .ani-down {
      -webkit-animation: rotate2 2s;
      animation: rotate2 2s;
      -webkit-animation-delay: 0s;
      animation-delay: 0s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
    }

    // .l-minting.aos-init.aos-animate {
    //     width: 120px;
    // }

    // .l-minting.aos-init.aos-animate a.item{
    //     width: 120px;
    //     height: 120px;
    // }


    @-webkit-keyframes rotate2 {
      100% {
        margin-bottom: 0;
      }
      50% {
        margin-bottom: -2rem;
      }
      0% {
        margin-bottom: 0;
      }
    }

    @keyframes rotate2 {
      100% {
        margin-bottom: 0;
      }
      50% {
        margin-bottom: -2rem;
      }
      0% {
        margin-bottom: 0;
      }
    }


    .item {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: 100px;
      height: 100px;
      //border-radius: 50%;
      //background-color: #991cc9;
      //background-image: url("../assets/img/pages/index/floating_banner.png");
      //background-size: cover;
      //-webkit-box-shadow: 0 3px 10px rgb(0 0 0 / 45%);
      //box-shadow: 0 3px 10px rgb(0 0 0 / 45%);

      > a {
        display: block;
        width: 100%;
        height: 100%;
      }

      .img {
        position: absolute;
        top: 40%;

        -webkit-transform: translateY(-60%);
        transform: translateY(-60%);
        z-index: 10;
      }
    }

    .item__text {
      position: absolute;
      top: 50%;
      left: 60px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      z-index: 10;
    }


    @-webkit-keyframes Floatingy {
        0% {-webkit-transform: translate(0)}
        65%{-webkit-transform: translateY(10px)}
        to {-webkit-transform: translate(0)}
    }

    @-webkit-keyframes Floatingy1 {
        0% {-webkit-transform: translate(0)}
        65% {-webkit-transform: translateY(15px)}
        to {-webkit-transform: translate(0)}
    }

    @-webkit-keyframes Floatingy2 {
        0% {-webkit-transform: translate(0)}
        65% {-webkit-transform: translateY(10px)}
        to {-webkit-transform: translate(0)}
    }

  }

  @media (max-width: 480px) {
    .ac-introduction .steps-wrap.md-layout {
    height: 460px;
    }

    .ac-introduction .steps-wrap .md-layout-item {
    display: block;
    margin: 0 auto;
    margin-bottom: 24px;
    }

    .l-minting.aos-init.aos-animate {
    width: 80px !important;
    }

    .l-minting.aos-init.aos-animate a.item{
    width: 80px !important;
    height: 80px !important;
    }

    .l-minting.aos-init.aos-animate a.item .img{
    width: 80px !important;
    height: 80px !important;
    }
}
</style>

