const setBase = () => {
  // --> todo : local test code
  if (window.__ANIPANG_CLUB_SERVICE_ENV__.base.isDomestic === '##isDomestic') {
    window.__ANIPANG_CLUB_SERVICE_ENV__.base.isDomestic = 'true';
  }
  if (window.__ANIPANG_CLUB_SERVICE_ENV__.base.service === '##service') {
    const service = 'anypangclub';

    window.__ANIPANG_CLUB_SERVICE_ENV__.base.service = service;
    window.__ANIPANG_CLUB_SERVICE_ENV__.base.baseUrl = `http://${location.hostname}:8090`;
  }
  if (window.__ANIPANG_CLUB_SERVICE_ENV__.base.env === '##env') {
    const key = 'development';
    const env = {
      production: 'production',
      development: 'development',
    };
    window.__ANIPANG_CLUB_SERVICE_ENV__.base.env = env[key];
  }
  window.__ANIPANG_CLUB_SERVICE_ENV__.base.isDomestic = JSON.parse(window.__ANIPANG_CLUB_SERVICE_ENV__.base.isDomestic);
  // <-- todo : local test code
  return window.__ANIPANG_CLUB_SERVICE_ENV__.base;
};

export default setBase();
