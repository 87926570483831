<template>
  <footer
    class="ac-footer footer footer-big"
    :class="{
      [`footer-${type}`]: type,
      [`footer-${size}`]: size
    }"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      <div class="flex flex-direction-row space-between">
        <nav class="ac-terms">
          <ul>
            <li>
              <router-link to="/termsofuse">
                {{language.common_footer_TermsOfUse}}
              </router-link>
            </li>
            <li>
              <router-link to="/privacypolicy">
                {{language.common_footer_PrivacyPolicy}}
              </router-link>
            </li>
            <li>
              <router-link to="/servicepolicy">
                {{language.common_footer_ServicePolicy}}
              </router-link>
            </li>
          </ul>
        </nav>
        <div class="copyright float-right">
          {{language.common_footer_pm_copyright}}
        </div>
      </div>
      <div  v-if="base.isDomestic && locale === 'ko'" class="content">
        <hr>
        <div class="ac_info">
          {{language.ko_footer_pm_name}} ㅣ
          {{language.ko_footer_pm_CEO_name}} ㅣ
          {{language.ko_footer_pm_registration_number}} ㅣ
          {{language.ko_footer_pm_mailorder_number}} ㅣ
          {{language.ko_footer_pm_address}} ㅣ
          {{language.ko_footer_pm_tel}} ㅣ
          {{language.ko_footer_pm_fax}} ㅣ
          {{language.ko_footer_pm_mail}} ㅣ
        </div>
      </div>
    </div>

  </footer>
</template>
<script>
  import { mapGetters } from 'vuex';

  export default {
    props: {
      backgroundColor: String,
      type: String,
      size: String
    },
    computed: {
      ...mapGetters([
        "base",
        'locale',
        'language',
      ]),
    },
    data() {
      return {

      };
    }
  };
</script>
<style lang="scss">
  .ac-footer {
    background: #0c0a26;

    .ac-terms {
      font-size: 13px;
      font-weight: 500;

      a:not(.md-button):hover {
        color: #77eaff !important;
      }
      a:hover,
      a:focus {
        color: #77eaff !important;
      }
    }
    hr {
      border-color: #363A63;
    }

    .ac_info {
      color: #919191;
      text-align: left;
      font-size: 13px;
    }

    .copyright {
      font-size: 13px;
    }
  }
</style>
