<template>
  <el-alert
    v-if="isToast"
    class="toast-popup"
    :title="language.common_toast_popup_title"
    type="error"
    show-icon>
  </el-alert>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: "ToastPopup",
    computed: {
      ...mapGetters([
        "isToast",
        'language',
      ]),
    },
  }
</script>

<style lang="scss">
  .toast-popup {
    position: absolute !important;
    margin: 0 auto !important;
    top: 0;
    width: 100% !important;
    z-index: 100000;
  }
</style>