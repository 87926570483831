// =========================================================
// * Vue Material Kit PRO - v1.4.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-kit-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from "vue";
import VueGtag from 'vue-gtag';
import store from '@/store';
import VueGtm from "@gtm-support/vue2-gtm";
import { sync } from 'vuex-router-sync'
import App from "./App.vue";
import router from './router/index'
import MaterialKit from "./plugins/material-kit";

Vue.use(MaterialKit);
Vue.use(VueGtm, {
  id: "GTM-5TCV3RF",
  vueRouter: router,
});
Vue.use(VueGtag, {
  appName: 'Anipang Club Dev',
  pageTrackerScreenviewEnabled: true,
  pageTrackerExcludedRoutes: [
    '/roadmap',
    '/mypage',
  ],
  config: {
    id: 'GTM-5TCV3RF',
  }
}, router);
Vue.config.productionTip = false;

sync(store, router);

new Vue({
  el: '#app',
  store,
  render: h => h(App),
  router,
});